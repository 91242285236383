import React from 'react';
import './productComponent.css'

const ProductComponent = ({productLogo , productName , productTitle , productDescription }) => {
    return (
        <div className='product-component'>
            <img className='product-logo' src={productLogo} alt="Logo with title"/>
            <p className='product-name'>{productName}</p>
            <a className='product-title' href="https://genl.tryouts.tech/" target="_blank" rel="noopener noreferrer">{productTitle}</a>
            <p className='product-description'>{productDescription}</p>
        </div>
    )
}

export default ProductComponent