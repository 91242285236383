import './blogListComponent.css'

const BlogListComponent = ({ blogTitle, blogTitleImg, blogSubtitle, blogTime, onClick }) => {

    return (
        <div className='blog-list-component'>
            <img src={blogTitleImg} className='blog-list-img' alt="Logo with title"></img>
            <p className='blog-list-title'>{blogTitle}</p>
            <p className='blog-list-subtitle'>{blogSubtitle}</p>
            <div className='blog-list-bottom'>
                <p className='blog-list-time'>{blogTime}</p>
                <button className="blog-list-read-more" onClick={onClick}>READ MORE &gt;&gt;</button>
            </div>
        </div>
    )
}

export default BlogListComponent