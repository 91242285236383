import './tableOfContentComponent.css'

const TableOfContentComponent = ({ chapterTitle, id }) => {

    const ScrollToComponent = (id) => {
        const component = document.getElementById(id);
        const headerOffset = 100;
        if (component) {
            window.scrollTo({
                top: component.offsetTop - headerOffset,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='table-of-content-component' onClick={() => {
            ScrollToComponent(id)
        }}>
            <hr />
            <p className='table-of-content-component-text'>{chapterTitle}</p>
        </div>
    )
}

export {
    TableOfContentComponent
}