import React from 'react';
import './header.css'
import logoWhiteWithNoTitle from '../../assets/logo-white-no-title.svg';
import { Drawer, Divider, Stack, List, ListItem, ListItemText, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const [open, setOpen] = React.useState(false)
    const isBigScreen = useMediaQuery({ query: '(min-width: 901px)' })
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const ScrollToComponent = (id) => {
        const component = document.getElementById(id);
        if (component) {
            window.scrollTo({
                top: component.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const GetList = () => {
        return (
            <List className='nav-list'>
                <ListItem className="nav-item-header" onClick={() => {
                    navigate('/')
                    ScrollToComponent('home-component')
                }}>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem className="nav-item-header" onClick={() => {
                    navigate('/')
                    ScrollToComponent('service-page')
                }}>
                    <ListItemText primary="Services" />
                </ListItem>
                <ListItem className="nav-item-header" onClick={() => {
                    navigate('/')
                    ScrollToComponent('about-page')
                }}>
                    <ListItemText primary="About Us" />
                </ListItem>
                <ListItem className="nav-item-header" onClick={() => {
                    navigate('/')
                    ScrollToComponent('product-page')
                }}>
                    <ListItemText primary="Our Products" />
                </ListItem>
                <ListItem className="nav-item-header" onClick={() => {
                    navigate('/blog')
                }}>
                    <ListItemText primary="Blogs" />
                </ListItem>
                <ListItem className="nav-item-header" onClick={() => {
                    navigate('/contact')
                }}>
                    <ListItemText primary="Contact Us" />
                </ListItem>
            </List >
        );
    }

    const NavBarSmallDevice = () => {
        return (
            <div>
                <Stack direction='horizontal' className='navbar-small-device'>
                    <img src={logoWhiteWithNoTitle} alt="Logo with title" className='header-logo' />
                    <IconButton color="inherit" onClick={toggleDrawer} className='drawer-icon'>
                        <MenuIcon />
                    </IconButton>
                </Stack>
                <div>
                    <Drawer className='drawer' anchor="right" open={open} >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronRightIcon />
                        </IconButton>
                        <Divider />
                        <GetList />
                        <Divider />
                    </Drawer>
                </div>
            </div >
        )
    }

    const NavBarBigDevice = () => {
        return (
            <div>
                <Stack direction='horizontal' className='navbar-big-device'>
                    <img src={logoWhiteWithNoTitle} alt="Logo with title" className='header-logo' />
                    <nav className="navbar">
                        <GetList />
                    </nav>
                </Stack>
            </div>
        )
    }

    return (
        <header className='fixed-header'>
            {isBigScreen && <NavBarBigDevice />}
            {!isBigScreen && <NavBarSmallDevice />}
        </header>
    )
}

export default Header;