import React from 'react';
import './homeComponent.css'
import mainPageImage from '../../assets/home-page-img.svg'
import { TypeAnimation } from 'react-type-animation';

const AnimatedText = () => {
    return (
        <div>
            <TypeAnimation
                className='first-text-home-component'
                sequence={[
                    "Empower Your Vision with Us : Turning Concepts into Reality!",
                    2000,
                    "Empower Your Vision with Us : Where Ideas Become Achievements!",
                    2000,
                    "Empower Your Vision with Us : Building Success, Step by Step!",
                    2000,
                    "Empower Your Vision with Us : Creating Impactful Solutions Together!",
                    2000,
                    "Empower Your Vision with Us : Bridging Innovation and Results!",
                    2000
                ]}
                speed={50}
                repeat={Infinity}
            />
        </div>
    );
};


const HomeComponent = () => {

    return (
        <div className='home-component' id='home-component'>
            <div className='home-component-para'>
                <AnimatedText />
                <p className='second-text-home-component'>Embark on a transformative journey with our innovative team,
                    where your ideas are not just heard but meticulously crafted into reality.
                    At our creative hub, we specialize in turning visions into tangible, impactful solutions.
                    Collaborate with us to experience the seamless fusion of imagination and expertise, bringing your concepts to vibrant life.
                    Together, we navigate the bridge from concept to creation, ensuring that every idea finds its place in the realm of reality.</p>
            </div>
            <img src={mainPageImage} alt="Logo with title" className='home-component-image' />
        </div >
    )
}

export default HomeComponent;