import './blogChapter.css'

const BlogChapter = ({ blogChapterTitle, blogChapterImg, blogChapterContent , id }) => {

    return (
        <div className='blog-chapter' id = {id}>
            <p className='blog-chapter-title'>{blogChapterTitle}</p>
            <img src={blogChapterImg} className={blogChapterImg ? 'blog-chapter-img' : 'hidden'} alt="Logo with title" ></img>
            <p className='blog-chapter-content'>{blogChapterContent}</p>
        </div>
    )
}

export default BlogChapter