import React from 'react';
import './footer.css'
import Stack from '@mui/material/Stack';
import logoWhiteWithNoTitle from '../../assets/logo-white-no-title.svg';
import instagramLogo from '../../assets/instagram-icon.svg';
import linkedinLogo from '../../assets/linkedin-icon.svg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();
    
    const ScrollToComponent = (id) => {
        const component = document.getElementById(id);
        if (component) {
            window.scrollTo({
                top: component.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='footer' >
            <Stack direction='horizontal'>
                <div className='footer-div'>
                    <img src={logoWhiteWithNoTitle} alt="Logo with title" className='footer-image' />
                    <p className='footer-text'>We help businesses in building and providing their consumers digital products of top-notch quality.</p>
                    <Stack direction='horizontal'>
                        <div className='footer-icon-div'>
                            <a href='https://www.linkedin.com/company/tryouts-tech' target="_blank" rel="noopener noreferrer">
                                <img src={linkedinLogo} alt="Logo with title" className='footer-icon' />
                            </a>
                            <a href='https://www.instagram.com/t.r.y.o.u.t.s/' target="_blank" rel="noopener noreferrer">
                                <img src={instagramLogo} alt="Logo with title" className='footer-icon' />
                            </a>
                        </div>
                    </Stack>
                </div>
                <div className='footer-div'>
                    <p className='footer-heading'>Pages</p>
                    <ul className='footer-list'>
                        <li className="nav-item-clickable" onClick={() => {
                            navigate('/')
                            ScrollToComponent('home-page')
                        }}>Home</li>
                        <li className="nav-item-clickable" onClick={() => {
                            navigate('/')
                            ScrollToComponent('service-page')
                        }}>Services</li>
                        <li className="nav-item-clickable" onClick={() => {
                            navigate('/')
                            ScrollToComponent('about-page')
                        }}>About Us</li>
                        <li className="nav-item-clickable" onClick={() => {
                            navigate('/')
                            ScrollToComponent('product-page')
                        }}>Our Products</li>
                        <li className="nav-item-clickable" onClick={() => {
                            navigate('/blog')
                        }}>Blogs</li>
                        <li className="nav-item-clickable" onClick={() => {
                            navigate('/contact')
                        }}>Contact Us</li>
                    </ul>
                </div>
                <div className='footer-div'>
                    <p className='footer-heading'>Services</p>
                    <ul className='footer-list'>
                        <li className="nav-item">Graphic Designing</li>
                        <li className="nav-item">Logo Designing</li>
                        <li className="nav-item">Video Editing</li>
                        <li className="nav-item">App Development</li>
                        <li className="nav-item">Web Development</li>
                        <li className="nav-item">Web Design</li>
                        <li className="nav-item">App Design</li>
                    </ul>
                </div>

                <div className='footer-div'>
                    <p className='footer-heading'>Contact Us</p>
                    <ul className='footer-list'>
                        <li className="nav-item-clickable" onClick={() => window.location = 'mailto:contact@tryouts.tech'}>contact@tryouts.tech</li>
                    </ul>
                </div>
            </Stack>
        </div>
    )
}

export default Footer;