import React, { useRef } from 'react';
import './about.css'
import AboutPageImage from '../../assets/about-page-img.svg'
import AboutPageDecor from '../../assets/about-page-decor.svg'
import { motion, useInView } from "framer-motion";
const ImageComponent = () => {
    const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
    const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;
    const ref = useRef(null);
    const inView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

    return (
        <div className='about-page-image-component'>
            <motion.div
                ref={ref}
                initial={{ WebkitMaskImage: hiddenMask, maskImage: hiddenMask }}
                animate={
                    inView
                        ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                        : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1, delay: 1 }}>
                <img src={AboutPageImage} alt="Logo with title" className='about-page-image'/>
            </motion.div>
        </div>
    );
}
const About = () => {
    return (
        <div id='about-page' className='about-page' >
            <div className='about-page-para'>
                <img src={AboutPageDecor} alt="Logo with title" className='about-page-decor' />
                <p className='first-text-about'>We are an <span className='gradient-text'>innovative and skilled team of designers and developers</span> based in India.</p>
                <p className='second-text-about'>At TryOuts, we take pride in our creative and talented team of designers and developers located in India.
                    Our passion for innovation drives us to deliver exceptional solutions that cater to the unique needs of our clients.
                    From cutting-edge design concepts to robust development strategies, we work collaboratively to bring visions to life.
                    Explore the synergy of creativity and technical expertise with our dynamic team at TryOuts.</p>
            </div>
            <ImageComponent />
        </div>
    )
}

export default About;