import './App.css';
import BlogList from './pages/blogList/blogList.js';
import Blog from './pages/blog/blog.js'
import Home from './pages/home/home.js'
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import ContactPage from './pages/contact/contact.js';

function App() {
  return (
    <div className='main-page'>
      <Router>
        <Routes>
          <Route exact path="/" element ={ <Home/>} />
          <Route exact path="/blog" element={<BlogList/>} />
          <Route exact path="/blog/:name" element={<Blog/>} />
          <Route exact path="/contact" element={<ContactPage/>}/>
        </Routes>
      </Router>
    </div>
  );
}



export default App;
