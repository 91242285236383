import React, { useState } from 'react';
import './ServiceComponent.css'
import { motion } from "framer-motion";

const ImageGrid = ({ images }) => {
    return (
        <div className="image-grid">
            {images.map((image, index) => (
                <div key={index} className="image-container">
                    <motion.a whileHover={{ scale: 1.3 }}>
                        <img src={image.src} alt={`img-${index}`} className="grid-image" />
                        <p className="image-caption">{image.caption}</p>
                    </motion.a>
                </div>
            ))}
        </div>
    );
};

const ServiceCardComponentBack = ({ handleFlip, isFlipped, images }) => {
    return (
        <div className={isFlipped ? `service-card-component-back` : `hidden`} onClick={handleFlip}>
            <ImageGrid images={images} />
        </div>
    )
}

const ServiceCardComponentFront = ({ imgSrc, altText, firstText, secondText, handleFlip, isFlipped }) => {
    return (
        <div className={!isFlipped ? `service-card-component-front` : `hidden`}>
            <img src={imgSrc} alt={altText} className='service-component-logo' />
            <p className='first-text-service-component'>{firstText}</p>
            <p className='second-text-service-component'>{secondText}</p>
            <button className="submit-btn" onClick={handleFlip}>View More</button>
        </div>
    )
}

const ServiceComponent = ({ imgSrc, altText, firstText, secondText, images }) => {

    const [isFlipped, setFlipped] = useState(false);

    const handleFlip = () => {
        setFlipped(!isFlipped);
    };

    return (
        <div className={`service-component-div ${isFlipped ? "flipped" : ""}`}>
            <ServiceCardComponentFront imgSrc={imgSrc} altText={altText} firstText={firstText} secondText={secondText}
                handleFlip={handleFlip} isFlipped={isFlipped} />
            <ServiceCardComponentBack images={images}
                handleFlip={handleFlip} isFlipped={isFlipped} />
        </div>
    )
}

export default ServiceComponent;