// export const BASE_URL = "https://lead-gen-backend.onrender.com/api/v1"
export const BASE_URL = "http://localhost:3333/api/v1"
export const SERVICES_OFFERED = [
    {
        imgSrc : "/web-icon.svg",
        altText : "Web Icon",
        firstText : "Web Development",
        secondText : "Web development is the work involved in developing a website for the Internet or an intranet.",
        images : [
            { src: '/html.png', caption: 'HTML' },
            { src: '/css.png', caption: 'CSS' },
            { src: '/react.png', caption: 'React Js' },
            { src: '/nodejs.png', caption: 'Node.js' },
            { src: '/php.png', caption: 'PHP' },
            { src: '/fastapi.png', caption: 'Fast Api' },
        ]
    },
    {
        imgSrc : "/ui-icon.svg",
        altText : "UI Icon",
        firstText : "UI/UX Design",
        secondText : "UX design refers to the term “user experience design” while UI stands for “user interface design”.",
        images : [
            { src: '/figma.png', caption: 'Figma' },
            { src: '/adobexd.png', caption: 'Adobe XD' }
        ]
    },
    {
        imgSrc : "/app-icon.svg",
        altText : "App Icon",
        firstText : "App Development",
        secondText : "App development is the process by which a mobile app is developed for one or more mobile devices, which can include personal digital assistants, etc.",
        images : [
            { src: '/android.png', caption: 'Android' },
            { src: '/react.png', caption: 'React Native' },
            { src: '/flutter.png', caption: 'Flutter' },
            { src: '/swift.png', caption: 'Swift' }
        ]
    },
    {
        imgSrc : "./graphics-icon.svg",
        altText : "Graphics Icon",
        firstText : "Graphic Design",
        secondText : "Graphic design is defined as the art and practice of planning and projecting ideas and experiences with visual and textual content.",
        images : [
            { src: '/photoshop.png', caption: 'Photoshop' },
            { src: '/illustrator.png', caption: 'Illustrator' },
            { src: '/aftereffects.png', caption: 'After Effects' },
            { src: '/canva.png', caption: 'Canva' }
        ]
    }
]