import { BoxesLoader, XlviLoader } from "react-awesome-loaders-py3";
import "./xlviLoader.css"
export const XlviLoaderComponent = () => {
    return (
        <div className='loader-element'>
            <XlviLoader
                boxColors={["#EF4444", "#F59E0B", "#6366F1"]}
                desktopSize={"100px"}
                mobileSize={"50px"}
            />
        </div>
    );
};


{/* <BoxesLoader
    boxColor={"#6366F1"}
    style={{ marginTop: "40px" }}
    desktopSize={"128px"}
    mobileSize={"80px"}
/> */}

