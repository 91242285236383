import React, { useEffect, useState } from 'react';
import './contact.css'
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import EmailVector from '../../assets/email-vector.svg'
import PhoneVector from '../../assets/phone-vector.svg'
import instagramLogo from '../../assets/instagram-icon.svg';
import linkedinLogo from '../../assets/linkedin-icon.svg';
import { XlviLoaderComponent } from '../../components/Loaders/xlviLoader.js';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data Submitted: ', formData);
        setFormData({ name: '', email: '', mobile: '', message: '' });
    };

    return (
        <div className="contact-us-wrapper">
            {/* Left Section: Quote and Contact Info */}
            <div className="left-section">
                <div className="quote-section">
                    <p className="quote">
                            "<span className='gradient-text'>Innovation distinguishes between a leader and a follower.</span>" – Steve Jobs
                    </p>
                </div>
                <p className='contact-info-header'>Find Us Here</p>
                <div className="contact-info">
                    <div className="contact-info-component">
                        <img src={EmailVector} alt="Logo with title"></img>
                        <div>
                            <p className="contact-info-component-header">Email:</p>
                            <p className="contact-info-component-val" onClick={() => window.location = 'mailto:contact@tryouts.tech'}>contact@tryouts.tech</p>
                        </div>
                    </div>
                    <div className="contact-info-component">
                        <img src={PhoneVector} alt="Logo with title"></img>
                        <div>
                            <p className="contact-info-component-header">Phone Number:</p>
                            <p className="contact-info-component-val">+91 7084110229</p>
                        </div>
                    </div>
                    <div className="social-links">
                        <a href='https://www.linkedin.com/company/tryouts-tech' target="_blank" rel="noopener noreferrer">
                            <img src={linkedinLogo} alt="Logo with title" className='footer-icon' />
                        </a>
                        <a href='https://www.instagram.com/t.r.y.o.u.t.s/' target="_blank" rel="noopener noreferrer">
                            <img src={instagramLogo} alt="Logo with title" className='footer-icon' />
                        </a>
                    </div>
                </div>
            </div>

            {/* Right Section: Form */}
            <div className="right-section">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            placeholder="Your Name"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            placeholder="Your Email"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            required
                            placeholder="Your Mobile Number"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            placeholder="Your Message"
                        />
                    </div>
                    <button type="submit" className="submit-btn">Send Message</button>
                </form>
            </div>
        </div>
    );
};


const ContactPage = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div>
                <XlviLoaderComponent />
            </div>
        );
    }

    return (
        <div className='contact-page'>
            <Header />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default ContactPage;