import React from 'react';
import './product.css'
import ContactPageDecor from '../../assets/contact-page-decor.svg'
import GenlLogo from '../../assets/genl-logo.svg'
import ProductComponent from '../productComponent/productComponent';

const Product = () => {

    return (
        <div id='product-page' className='product-page'>
            <img className='product-page-decor' src={ContactPageDecor} alt="Logo with title" />
            <p className='first-text-product'>Our Products</p>
            <div className='products'>
                <ProductComponent
                    productLogo={GenlLogo}
                    productName={"Lead Generation Website"}
                    productTitle={"GenL"}
                    productDescription={"Boost your business with a lead-providing website that connects you with ready-to-convert customers. Our platform delivers high-quality leads through targeted strategies and optimized user experiences, ensuring you get the right prospects to drive your sales and growth."} 
                    />
            </div>
        </div>
    )

}

export default Product