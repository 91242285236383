import React, { useEffect, useState } from 'react';
import './home.css'
import ServiceOffered from '../../components/servicesOffered/ServicesOffered.js'
import About from '../../components/about/about.js'
import Product from '../../components/products/product.js';
import Header from '../../components/header/header.js'
import Footer from '../../components/footer/footer.js'
import HomeComponent from '../../components/homeComponent/homeComponent.js';
import { XlviLoaderComponent } from '../../components/Loaders/xlviLoader.js';

const Home = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div>
                <XlviLoaderComponent />
            </div>
        );
    }

    return (
        <div className='home-page' >
            <Header />
            <HomeComponent />
            <ServiceOffered />
            <About />
            <Product />
            <Footer />
        </div>
    )
}

export default Home;